/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
/* font-family: 'Montserrat', sans-serif;  */
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Chakra Petch', sans-serif;
  color: #282828;
  overflow-y: scroll !important;
}
@font-face {
  font-family: 'Barney Rounded PERSONAL USE Regular';
  font-style: normal;
  font-weight: normal;
   src: local('Barney Rounded PERSONAL USE Regular'),
   url('../src/assets/font/BarneyRoundedPersonalUseRegular-mLvPv.woff') format('woff');
  }
/* Custom Scrollbar */
/* *::-webkit-scrollbar {
  width: 6px;
  height: 5px;
} */

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #b5b0c2; 
}

.navbar-sticky--moved-up {

  background: #00286d;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.25);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 10;
}
.main_navbar_mob{
  top: 0;
}
.main_navbar .navbar_right{
  display: flex;
}
.main_navbar .navbar_right .dropdown .primary_btn{
  width: 200px;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  text-decoration: none;
}
.main_navbar .navbar_right .dropdown-toggle::after{
  position: absolute;
  right: 9px;
  top: 22px;
} 
.main_navbar .navbar-brand{
  outline: 0 !important;
  width: 155px;
}
.main_navbar .primary_btn {
  display: flex;
  align-items: center;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;
}
.main_navbar .navbar-toggler{
  box-shadow: none;
  font-size: 19px;
  color: aliceblue;
}
.main_navbar .btn-close{
  opacity: 1;
  /* filter: invert(1); */
}
.mob_bg .main_navbar {
  background: #00286d;
}
.main_navbar .offcanvas {
  border: 0;
  background: #0e5283;
  background: #e4e5e7;
}

.primary_modal input{
  box-shadow: none !important;
  border: 1px solid #95a4b4;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background-color: #e4e5e7;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #c9c9c9;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid #c9c9c9;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border-color: #0166cb;
}
.primary_modal .modal-header h5 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: #000;;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}
.dashboardloader{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-close {
  width: 32px;
  height: 32px;
  border: 0;
  box-shadow: none !important;
  /* filter: invert(1) brightness(60.5); */
  opacity: 1;
}
.primary_modal a {
  text-decoration: none;
}
.head {
  max-width: 75%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
}

.h5tag {
  font-size: 24px;
  color: #0068b3;
  font-weight: 300;
}

.h2tag {
  color: #0068b3;
  font-weight: 800;
  font-size: 56px;
}

.para {
  color: #282828;
}

.primary_btn {
  padding: 11px 15px;
  border: 0;
  border-radius: 10px;
  background: linear-gradient(359deg, #00c160, springgreen);
  font-size: 18px;
  color: #002051;
  font-weight: 700;
  margin: auto;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none;
}
.primary_btn::before {
  content: "";
  position: absolute;
  background: #08ff83;
  transform: scale(2);
  width: 20%;
  height: 100%;
  z-index: -1;
  transition: 1s;
  border-radius: 50%;
  left: -100%;
}
.primary_btn:hover::before {
  width:  100%;
  left: 0;
}

.above_info_box {
  background: #00c160;
  padding: 10px 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
}
/* .above_info_box  .container>div{
  padding-bottom: 25px;
} */

/* .above_info_box  ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
} */
/* .above_info_box  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.above_info_box  ::-webkit-scrollbar-thumb {
  background-color: #ff0000;
} */


.above_info_box .above_box_flx {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.above_info_box .above_box_flx div {
  display: flex;
  flex-wrap: nowrap;
}

.above_info_box .above_box_flx div p {
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 0;
  white-space: nowrap;
}

.above_info_box .above_box_flx div span {
  color: #fff;
  font-size: 14px;
}

.above_info_box.above_fixed {
  position: fixed;
  width: 100%;
  top: 0;
  /* z-index: 999; */
}


.page_header {
  background: url("./assets/images/banbg.png") no-repeat center;
  text-align: center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 50px;
}

.page_header h6 {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
}

.page_header h1 {
  font-size: 100px;
  font-weight: 800;
  color: #fff;
  text-shadow: -2px -2px 2px #00ffce;
}

.global {
  padding: 80px 0 70px;
  position: relative;
}
.global .col-lg-5{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.global .img_box_out{
  border: 1px solid #cde7f8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 575px;
  height: 575px;
  transform: scale(.9);
}
.global .img_box{
  background: linear-gradient(11deg, #0166cb, transparent);
  border-radius: 50%;
  width: 570px;
  height: 570px;
  border: 25px solid #fff;
  position: relative;
}
.global .img_box::before{
  content: "";
  position: absolute;
  background: #ffffff;
  width: 50%;
  height: 7%;
  bottom: -36px;
  right: 0;
  z-index: 1;
  left: 0;
  margin: 0 auto;
}
.global .flx{
  display: flex;
  flex-wrap: wrap;
  transform: rotate(45deg) scale(0.8) translateY(6px) translateX(2px) translateY(-7px);
}
.global .box{
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  margin: 5px;
  width: 250px;
  border: 1px solid #cde7f8;
}
.global .box:hover{
  background: #0166cb;
}
.global .box >div{
  transform: rotate(-45deg);
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.global .box .imgb{
  background: #00c160;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.global .box p{
  font-weight: 600;
  color: #0068b3;
  margin-top: 15px;
  margin-bottom: 0;
  text-transform: uppercase;
} 
.global .box:nth-last-child(1){
  background: linear-gradient(white, white) padding-box, linear-gradient(to right, #72abe4, #9932cc00) border-box;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
  /* background: url("./assets/images/boxbg.png") no-repeat center; */
}
.global .box:nth-last-child(1)::before{
  content: "";
  position: absolute;
  bottom: -19px;
  right: -14px;
  width: 270px;
  height: 260px;
  border-radius: 50%;
  background: linear-gradient(white, white) padding-box, linear-gradient(135deg, #00008b00 70%, #cbe7ff 50%) border-box;
  border: 1px solid transparent;

  background: linear-gradient( 324deg, #237ad2 , white 40%) padding-box, linear-gradient(135deg, #00008b00 70%, #cbe7ff 50%) border-box;

}
.global .box:nth-last-child(1):hover:before{
  background: #0166cb;
}
.global .box:nth-last-child(1)::after{
  content: "";
  position: absolute;
  /* background: #72abe4; */
  width: 50%;
  height: 50%;
  bottom: 0;
  right: 0;
  filter: blur(51px);
}
.global .box:nth-last-child(1):hover{
  background: linear-gradient(#0166cb, #0166cb) padding-box, linear-gradient(to right, #72abe4, #9932cc00) border-box;
  border: 1px solid transparent;
}
.global .box:hover p{
  color: #fff;
}
.global .box:hover .imgb img{
  filter: invert(26%) sepia(76%) saturate(6786%) hue-rotate(192deg) brightness(93%) contrast(102%);
}
.global::before {
  content: "";
  position: absolute;
  background: url("./assets/images/global_bg.png") no-repeat center;
  width: 70%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
}
/* 
.global .h5tag {
  line-height: 1px;
  margin-top: 30px;
} */



.p_activity {
  padding-bottom: 50px;
}

.p_activity p {
  margin-bottom: 0;
}

/* .p_activity p.violet{
  color: #975fff;
} */
/* .p_activity p.green span{
  color: #06c8b2;
} */
.p_activity .activity_box {
  background: linear-gradient(176deg, #e1f2fe, #ffffff 50%) padding-box, linear-gradient(356deg, #dfeffb, #dfeffb)border-box;
  border: 2px solid transparent;
  padding: 20px;
  border-radius: 20px;
  padding-top: 10px;
  padding-right: 10px;
}

.p_activity .activity_box_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfeffb;
  padding: 20px 5px;
}

.p_activity .activity_box_flx:hover {
  background: #f0f8fe;
}

.p_activity .activity_box_flx:nth-last-child(1) {
  margin-bottom: 20px;
}

.p_activity .activity_scroll {
  padding-right: 12px;
}

.p_activity .flxbox {
  display: flex;
  align-items: center;
}

.p_activity .flxbox div {
  margin-right: 30px;
}

.p_activity .flxbox .btn {
  background: #dfeffb;
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  margin-right: 0;
  border: 0;
  padding: 7px 25px;
}

.p_activity .rightbtn p {
  display: flex;
  align-items: center;
}

.p_activity .rightbtn p span {
  font-size: 15px;
}

.p_activity .activity_box .seemorebutn {
  width: 300px;
  padding: 15px;
  border: 0;
  border-radius: 10px;
  background: linear-gradient(359deg, #00c160, springgreen);
  font-size: 18px;
  color: #002051;
  font-weight: 600;
  margin: auto;
  display: block;
}

/* .p_activity .activity_box .seemorebutn .arrowhvr{
  display: none;
  }
.p_activity .activity_box .seemorebutn:hover .arrow{
  display: none;
}
.p_activity .activity_box .seemorebutn:hover .arrowhvr{
  display: inline-block;
} */

.plan_box {
  margin-bottom: -140px;
  z-index: 1;
  position: relative;
}

.plan_box .maxbox {
  background: linear-gradient(176deg, #e1f2fe, #ffffff 50%) padding-box, linear-gradient(356deg, #dfeffb, #dfeffb)border-box;
  border: 2px solid transparent;
  padding: 35px 20px;
  border-radius: 20px;
}

.plan_box .col-md-4 {
  border-right: 2px solid #dfeffb;
}

.plan_box .col-md-4:nth-last-child(1) {
  border-right: 0;
}

.plan_box .box {
  text-align: center;
}

.plan_box .box img {
  margin-bottom: 25px;
}

.plan_box .box h3 {
  color: #0068b3;
  font-size: 43px;
  font-weight: 700;
  margin-bottom: 0;
}

.plan_box .box p {
  font-size: 14px;
  color: #747679;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

.plan_exp {
  color: #fff;
  background: url("./assets/images/planbg.png") no-repeat center #002051;
  background-position-y: 57%;
  padding: 220px 0 60px;
}

.plan_exp .primary_btn {
  margin-top: 50px;
  font-size: 30px;
  padding: 11px 20px;
}

.plan_exp h5 {
  color: #fff;
  margin-bottom: 0;
}

.plan_exp h2 {
  color: #fff;
}

.plan_exp .img_box {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 70px;
}

.plan_exp p a {
  color: #fff;
  font-weight: 700;
}

.plan_exp p span {
  font-weight: 700;
}


.faq {
  padding: 55px 0 95px;
  position: relative;
}

.faq .accordion-item {
  border: 0 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 15px 0px;
  margin-bottom: 20px;
  border-radius: 30px;
}

.faq .accordion-collapse {
  transition: .5s;
}

.faq .accordion-button {
  box-shadow: none !important;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  transition: .5s;
}

.faq .accordion-button:not(.collapsed) {
  color: #00c160;
}

.faq .accordion-button::after {
  transition: .5s;
  height: 26px;
  width: 26px;
  background-size: contain;
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/minus.png");
}

.faq .accordion-button.collapsed::after {
  background-image: url("./assets/images/plus.png");
}

.faq::before {
  content: "";
  background: url("./assets/images/faq.png") no-repeat center;
  background-size: contain;
  width: 40%;
  position: absolute;
  right: 20px;
  top: 20px;
  bottom: 0;
  /* margin: auto 0; */
  z-index: -1;
  height: 630px;
}

.footer {
  background: #f2f2f2;
  padding: 14px 0 15px;
  text-align: center;
  z-index: 1;
}

.footer p {
  margin-bottom: 2px;
}

.footer .addr {
  color: #0068b3;
  margin-bottom: 10px;
  word-break: break-word;
}

.footer ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.footer ul li a {
  background: #a5a5a5;
  border-radius: 50%;
  /* padding: 5px; */
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f2f2;
  text-decoration: none;
  margin: 0 8px;
}

.footer ul li a:hover {
  background: #0068b3;
  color: #fff;
}

.footer ul li a i {
  font-size: 20px;
}
.plan_box_dash .col-lg-4 {
  border-right: 2px solid #dfeffb;
}

.plan_box_dash .col-lg-4:nth-last-child(1) {
  border-right: 0;
}
.dash_ban{
   background: url("./assets/images/dashban_bg.png") no-repeat center #000a28;
   padding-top: 150px;
   padding-bottom: 150px;
   color: #fff;
}
.dash_ban h2{ 
  font-size: 49px;
  margin-bottom: 30px;
  font-weight: 800;
  text-align: center;
}
.dash_ban .flx{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 35%;
  margin: 0 auto;
}
.dash_ban .flx span{
  white-space: nowrap;
}
.dash_ban .flx input{
    border-color: #fff;
    background: transparent;
    border-radius: 10px 0 0 10px;
    border-right: 0;
    color: #fff;
}
.dash_ban .flx .input-group-text{
  border-color: #fff;
  background: transparent;
  border-radius: 0px 10px 10px 0px;
  border-left: 0;
}
.dash_ban .flx .input-group-text img{
  cursor: pointer;
}
.dash_ban .refereshbtn{
  margin-top: 20px;
  display: inline-block;
}
.plan_box_dash{
  margin-top: -90px;
  margin-bottom: 30px;
}
.plan_box_dash .box{
 display: flex;
 align-items: center;
}
.plan_box_dash .box.two{
  justify-content: center;
}
.plan_box_dash .box.three{
  justify-content: flex-end;
}
.plan_box_dash .box img{
  margin-bottom: 0;
  margin-right: 13px;
}

.dashboard {
  margin-bottom: 60px;
}
.dashboard .grid{
  display: grid;
  grid-template-columns: 250px auto;
  gap: 30px;
}
.dashboard .box{
  background: linear-gradient(to bottom ,#e1f2fe,#fff);
  border: 2px solid #dfeffb;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  margin-bottom: 30px;
}
.dashboard .box p{
  color: #384456;
  margin-bottom: 10px;
  font-weight: 500;
}
.dashboard .box h5{
  font-weight: 700;
  margin-bottom: 0;
  font-size: 22px;
}
.dashboard .box span{
  font-size: 14px;
  color: #0068b3;
}
.dashboard .aff_box{
  display: block;
  padding: 25px;
}
.dashboard .aff_box input{
  background: transparent;
  border-color: #dfeaf1;
  box-shadow: none !important;
  border-radius: 10px 0 0 10px;
  color: #0068b3;
  border-right:0 ;
}
.dashboard .aff_box .input-group-text{
  background: transparent;
    border-color: #dfeaf1;
    box-shadow: none !important;
    border-radius: 0px 10px 10px 0px;
    border-left: 0;
}
.dashboard .aff_box .input-group-text img{
  cursor: pointer;
}
.dashboard .aff_box ul{
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding-left: 0;
}
.dashboard .aff_box ul a{
  margin: 0 8px;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #747679;
  border-radius: 50%;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.dashboard .aff_box ul a:hover{
  background: #0068b3;
}
.dashboard .box_bdr{
  border: 2px solid #dfeffb;
  border-radius: 25px;
  padding: 20px;
  padding-bottom: 14px;
}
.dashboard .box_bdr h6{
 text-align: center;
 color: #0166cb;
 font-size: 20px;
 font-weight: 600;
 margin-bottom: 15px;
}
.dashboard .box_bdr .box{
  padding: 15px;
  width: 100%;
  align-items: flex-start;
}
.dashboard .box_bdr .box .fl_x{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .box_bdr .box .fl_x button{
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  padding: 7px 10px;
  text-transform: uppercase;
  outline: 0;
}
.dashboard .box_bdr .box span{
  font-weight: 600;
}
.dashboard .busd_boxes {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(to bottom ,#e1f2fe,#fff);
  border: 2px solid #dfeffb;
  border-radius: 23px;
  padding: 15px;
} 
.dashboard .busd_boxes .selbox {
  margin: 0;
}
.dashboard .busd_boxes >div+div{
  margin-left: 15px !important;
}

.dashboard .busd_boxes .primary_btn{
  margin-top: 15px;
  padding: 0;
}
.dashboard .busd_boxes .primary_btn.disable{
  opacity: .5;
  cursor: default;
  outline: 0;
  padding: 0;
}
.dashboard .busd_boxes .disable{
  animation: unset;
}
.dashboard .busd_boxes .primary_btn.disable:hover::before{
  display: none;
}
.dashboard .selbox {
  background: linear-gradient(175deg, #0c84ff, #88c4ff);

  /* background: #f8fcff; */
  border: 2px solid #dfeffb;
  border: 2px solid #2585e5;
  padding:10px 15px;
  border-radius: 10px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;
}
.dashboard .board .selbox div {
  display: flex;
  align-items: center;
}
.dashboard .selbox.active {
  background: linear-gradient(187deg, #0099cd, #23dbb8);
  border: 2px solid #1bc971;
}
.dashboard .selbox.active p{
  color: #002b52;
  color: #fff; 
}
.dashboard .selbox p{
  color: #a7cae4;
  color: #397099;
  color: #fff; 
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.dashboard .lvl_flx{
  display: flex;
  align-items: center; 
  margin-top: 15px;
  background: linear-gradient(to bottom ,#e1f2fe,#fff);
  border: 2px solid #dfeffb;
  border-radius: 23px;
  padding: 15px;
  justify-content: center;
}
.dashboard .lvl_flx div+div{
 margin-left: 12px;
}
.dashboard .box_bdr .input-group{
  max-width: 75%;
  margin: 35px auto 30px;
}
.dashboard .box_bdr .form-control{
  border-radius: 10px;
  box-shadow: none !important;
  border-color: #dfeaf1;
}
.dashboard .box_bdr .form-control::placeholder{
  color: #747679;
}
.tooltip_data{
  font-size: 15px; 
  margin-bottom: 0;
}
.news{
  background: #0068b3;
  color: #fff;
  margin: 0;
  border-radius: 6px;
}
.news p{
  margin-bottom: 0;
  line-height: 11px;
  padding: 10px 0 5px;
}
.dashboard .box_bdr .input-group-text{
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #002051;
  background: linear-gradient(to top,#00c160,#00ff7f);
  border: transparent;
  cursor: pointer;
}
.dashboard .board {
  display: block;
}
.dashboard .board .heading{
  color: #747679;
  font-weight: 700;
  margin-bottom: 15px;
}
.dashboard .board .selbox{
  margin: 0 0 15px;
  padding: 5px 10px;
}
.dashboard .board .selbox img{
  margin-right: 4px;
}
.dashboard .board .flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard .board .flx img{
  height: 28px;
}
.dashboard .board .flx img.info_img {
  height: 20px;
}

.dashboard .board_chg .selbox {
  padding: 5px 15px;
  justify-content: space-between;
}
.dashboard .board_chg .selbox p{
 font-size: 16px;
}
.dashboard .board_chg .selbox span{
  font-size: 16px;
  color: #397099;
  color: #dfebf3;
  font-weight: 700;
}
.dashboard .board_chg .selbox.active span{
 color: #002c52;
 color: #fff;
}
.dashboard .board_chg .selbox.finished_lvl{
 /* background: linear-gradient(175deg, #9ac2eb, #375572); */
 border: 2px solid #a0afbf;

}
.dashboard  .selbox.finished_lvl{
 background: linear-gradient(175deg, #9ac2eb, #375572);
 /* border: 2px solid #a0afbf; */

}
.dashboard .total .selbox{
 background: none; 
 padding: 5px;
}
.dashboard .total .selbox p,
.dashboard .total .selbox span{
  color: #002c52 !important;
  font-size: 15px !important;
}
.dashboard .board_chg .selbox span.word-wrap{
  word-break: break-word;
}
.dashboard .board_chg .tot_flx{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  margin: 10px auto 0;
}
.dashboard .board_chg .tot_flx span{
  font-size: 24px;
  color: #002c52;
  margin: 0 8px;
}
.dashboard .history{
  border: 2px solid #dfeffb;
  border-radius: 25px; 
}
.dashboard .history .h5tag{
  padding-left: 30px !important;
  margin-top: 20px;
  margin-bottom: 20px !important;
}
.dashboard .rdt_Pagination{
  background: transparent;
  border: 0;
}
.dashboard td,.dashboard th{
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
}
.dashboard thead tr{
  background: #0068b3;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
}
.dashboard tbody tr:nth-child(odd){
  background: #f8fcff;
}
.dashboard tbody tr:nth-child(even){
  background: #f0f7fd;
}
.dashboard tr td:nth-child(1),.dashboard tr th:nth-child(1){
 border-radius:10px 0 0 10px;
 padding-left: 35px;
}
.dashboard tr td:nth-last-child(1),.dashboard tr th:nth-last-child(1){
  border-radius: 0 10px 10px 0;
  padding-right: 65px;
  text-align: right;
}
.dashboard tbody td{
  color: #747679;
  font-size: 14px;
  font-weight: 600;
}
.dashboard .rdt_TableHeadRow{
  border: 0;
  background: #0068b3;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 10px !important;
  margin-top: 22px;
}
.dashboard .rdt_Table{
  padding:5px;
}
.dashboard .rdt_TableRow,.dashboard .rdt_Table{
  border-radius: 10px !important;
}
.dashboard .rdt_TableRow:nth-child(odd){
  background: #f8fcff;
  border: 0;
}
.dashboard .rdt_TableRow:nth-child(even){
  background: #f0f7fd;
  border: 0;
}
.dashboard .rdt_TableRow .rdt_TableCell:nth-child(1),
.dashboard .rdt_TableHeadRow .rdt_TableCol:nth-child(1){
  max-width: 50px;
  padding-left: 30px;
}
.dashboard .rdt_TableRow .rdt_TableCell:nth-last-child(1),
.dashboard .rdt_TableHeadRow .rdt_TableCol:nth-last-child(1){
  text-align: right;
  justify-content: flex-end;
  padding-right: 100px;
}
.dashboard .rdt_TableRow .rdt_TableCell:nth-child(3),
.dashboard .rdt_TableHeadRow .rdt_TableCol:nth-child(3){
 min-width: 440px;
}
.dashboard .rdt_TableCell{
  color: #747679;
  font-size: 14px;
  font-weight: 600;
}
.dashboard .rdt_TableCell:nth-child(2)>div{
  overflow: visible;
}
.dashboard .mrgin{
  margin-bottom: 30px;
}
.dashboard .mrgin .col-md-9{ 
  padding-right: 15px;
}
.dashboard .mrgin .col-md-3{ 
  padding-left: 15px;
}
.dash_ban_mob{
  padding-bottom: 0;
 /* background-size: contain; */
}
.dash_ban_mob h2{
  font-size: 26px !important;
}
.dash_ban_mob .center{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dash_ban_mob .round{
  height: 230px;
  width: 230px;
  background: #fff;
  border-radius: 50%;
  border: 8px solid #00c160;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: -100px;
  z-index: 1;
}
.dash_ban_mob .round p{ 
  font-size: 22px;
  color: #747679;
  font-weight: 700;
  margin: 0;
}
.dash_ban_mob .round h5{
  color: #002c52;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.dashboard_mob {
  margin-top: 120px;
}
.dashboard_mob .box .fl_x{
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard_mob .box h5{
  font-size: 24px !important;
}
.dashboard_mob .box .fl_x button {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding: 7px 10px;
    text-transform: uppercase;
    outline: 0;
    width: 115px;
    height: 35px;
    border-radius: 5px;
    color: #002051;
}
.dashboard_mob .next{
  color: #0068b3;
  font-weight: 500;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.plan_pool{
  width: 3000px;
  margin-top: 40px;
}
.plan_pool img{
 z-index: 1;
 outline: 0;
}
.plan_pool_head {
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 56px;
 position: relative;
 margin: 10px auto 40px;
}

.plan_pool_head::after {
 content: "";
 background-color: #dfeffb;
 width: 1px;
 height: 57%;
 position: absolute;
 bottom: -40px;
}
.plan_pool_head_child {
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 16px;
 position: relative;
 margin: 29px 5px 0;
}
.plan_pool_first_row .plan_pool_head_child {
 width: 100px;
 height: 40px;
 font-size: 36px;
}
.plan_pool_first_row {
 display: flex;
 justify-content: space-around;
 position: relative;
}
.plan_pool_first_row::after {
 content: "";
 background-color: #dfeffb;
 width: 50%;
 
 width: 75%;
 height: 1px;
 position: absolute;
 z-index: -1;
}
.plan_pool_head_child::before {
 content: "";
 background: #2a2a2a00 url("./assets/images/down_arw.png") no-repeat bottom;
 width: 11px;
 height: 75%;
 position: absolute;
 top: -29px;
 z-index: -1;
}
.plan_pool_second_row .plan_pool_head_child {
 width: 32px;
 height: 45px;
 font-size: 30px;
 border-radius: 12px;
 margin-top: 26px;
}
.plan_pool_second_row > div {
 display: flex;
 justify-content: space-around;
 position: relative;
 margin-top: 30px;
 width: 50%;
 width: 22.5%;
}
.plan_pool_second_row {
 display: flex;
 justify-content: space-around;
}
.plan_pool_first_row .plan_pool_head_child::after{
 content: "";
 background-color: #dfeffb;
 width: 1px;
 height: 60%;
 position: absolute;
 bottom: -32px;
 z-index: -1;
}
.plan_pool_second_row > div::before{
 content: "";
 background-color: #dfeffb;
 width: 75%;
 height: 1px;
 position: absolute;
 z-index: -1;
}
.plan_pool_second_row .plan_pool_head_child::after{
  content: "";
  background-color: #dfeffb;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
  z-index: -1;
 }




 
.plan_pool_third_row {
  display: flex;
  justify-content: space-around;
}
 
.plan_pool_third_row .plan_pool_head_child::after { 
  width: 0px; 
} 
/* .plan_pool_third_row>div::before {
  content: "";
  background-color: #dfeffb;
  width: 50%;
  height: 1px;
  position: absolute;
}  */
.plan_pool_third_row >div{
  display: flex;
}
.plan_pool_third_row >div>div{
  display: flex;
}


 
/* glow  */

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 #00eb75;  
   color: transparent;
   height: 100%;
  }
  50% {
    box-shadow: 0 0 25px 0 #00eb75;
    box-shadow:0 0 7px 3px #00eb75; 
    color: #000a28;
    height: -100%;
  }
} 

.btn-glow { 
  -webkit-animation: glow 1s linear infinite;
          animation: glow 1.5s linear infinite; 

}  

  /* linear gradient border */
  /* .button{
    background: linear-gradient(359deg, #00c160, springgreen);
    font-size: 18px;
    color: #002051; 
    border: 0;
    background-color: transparent;
  }  */
.button {
	--border-radius: 8px;
	--border-width: 3px;
  
	appearance: none;
	position: relative;
  padding: 7px 10px;
	border: 0;
	background-color: transparent; 
	font-size: 12px;
	font-weight: 600;
  color: #002051;
  width: 120px;
  height: 38px;
  text-transform: uppercase; 
	border-radius: var(--border-radius);
  display: block;
}

.button::after {
	--m-i: linear-gradient(#000, #000);
	--m-o: content-box, padding-box;

	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: var(--border-width);
	border-radius: var(--border-radius);
	background-image: conic-gradient(
		#488cfb,
		#29dbbc,
		#ddf505,
		#ff9f0e,
		#e440bb,
		#655adc,
		#488cfb
	);

	mask-image: var(--m-i), var(--m-i);
	mask-origin: var(--m-o);
	mask-clip: var(--m-o);
	mask-composite: exclude;
	-webkit-mask-composite: destination-out;

	filter: hue-rotate(0);
	animation: hue linear 500ms infinite;
	animation-play-state: paused;
}

.button::after {
	animation-play-state: running;
}

@keyframes hue {
	to {
		filter: hue-rotate(1turn);
	}
}
 



/* pulse-button */

.pulse-button {  
  margin-left: -50px;
  margin-top: -50px;
  display: block;
  width: 100px;
  height: 33px;
  font-size: 13px; 
  text-transform: uppercase;
  text-align: center; 
  color: white;
  border: none;
  border-radius: 5px;
  background: #5a99d4;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.5);
  animation: pulse 1.5s infinite;
}

.pulse-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}


/* linear  */
.linear{
  transform: translate(0%, 0%);
  width: 100px;
  height: 33px;
  text-align: center; 
  color: #4f68b3;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none; 
  box-sizing: border-box;
  background: linear-gradient(90deg, yellow, orange, red, violet, purple, blue, yellow);
  background: linear-gradient(90deg, #00c160, #e6f4fe, #0cc668, #e5f4fe, #d8e1f1, #f9f7e7, #00c160);
  /* background: linear-gradient(90deg, #00286d, #00286d, #0cc668, #00286d, #00c763, #00286d, #00286d); */
  background: linear-gradient(90deg, #00c160, #08ff83, #0cc668, #08ff83, #08ff83, #11fd86, #00c160);
  background-size: 400%;
  border-radius: 7px;
  z-index: 1;
  border: 1px solid #00c160;
}

.linear {
  animation: animate 5s linear infinite; 
}
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
.linear:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  /* background: linear-gradient(45deg, #f15523, #ef3224, #7c3697); */
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: 0.5%;
}
.linear::before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}



/* border  */
.card { 
  position: relative;
  width: 100%;
  aspect-ratio: 1/1.5;
  overflow: hidden;
  /* box-shadow: 0 0 2em rgba(0, 0, 0, 0.5); */
  height: 33px;
  width: 110px;
  background: linear-gradient(359deg, #00c160, springgreen);
  font-size: 13px;
  font-weight: 600;
  color: #002051;
  text-transform: uppercase;
  padding: 7px 10px;
  text-align: center;
}
.card__line {
  position: absolute;
  animation: 1s 1s linear infinite;
}
.card__line_left {
  animation-name: to-top;
  background: linear-gradient(to bottom, #2bd9e9, transparent);
  background: linear-gradient(to bottom, #007fce, transparent);
  left: 0;
  bottom: -100%;
  width: 0.2em;
  height: 100%;
}
.card__line_right {
  animation-name: to-bottom;
  background: linear-gradient(to bottom, transparent, #2bd9e9);
  background: linear-gradient(to bottom, #007fce, transparent);
  right: 0;
  top: -100%;
  width: 0.2em;
  height: 100%;
}
.card__line_top {
  animation-delay: 1.5s;
  animation-name: to-right;
  background: linear-gradient(to right, transparent, #2bd9e9);
  background: linear-gradient(to right, transparent, #0068b3);
  width: 100%;
  height: 0.2em;
  height: 2px;
  top: 0;
  left: -100%;
}
.card__line_bottom {
  animation-delay: 1.5s;
  animation-name: to-left;
  background: linear-gradient(to right, #2bd9e9, transparent);
  background: linear-gradient(to bottom, #007fce, transparent);
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 0.2em;
}

@keyframes to-top {
  to {
    bottom: 200%;
  }
}
@keyframes to-bottom {
  to {
    top: 200%;
  }
}
@keyframes to-left {
  to {
    right: 200%;
  }
}
@keyframes to-right {
  to {
    left: 200%;
  }
}

/* 
.btn-glow :before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  filter: blur(30px);
  transform: translateX(-100px) skewX(-15deg);
}
.btn-glow :after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px) skewX(-15deg);
}
.btn-glow :hover:before {
  transform: translateX(300px) skewX(-15deg);
  opacity: 0.6;
  transition: 0.7s;
}
.btn-glow :hover:after {
  transform: translateX(300px) skewX(-15deg);
  opacity: 1;
  transition: 0.7s;
} */

.react-tooltip{
  background-color: #e3ecf1 !important;
  color: #747679 !important;
  font-size: 13px !important;
  border-radius: 10px;
  /* width: 170px !important; */
  opacity: 1 !important;
}


.inner_wrapper{
  padding-top: 150px;
  padding-bottom: 100px;
  min-height: calc(100vh - 89px );
}
.join_now_panel input{
  box-shadow: none !important;
}
.join_now_panel h2{
  font-size: 36px;
}
.join_now_panel label{
  font-size: 20px;
  font-weight: 500;
}
.join_now_panel .box{
border: 0 !important;
box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 15px 0px;
margin-bottom: 20px;
border-radius: 30px;
padding: 30px 15px;
}

.copied{
  color: #ffffff;
  text-align: center;
  margin-bottom: 0;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 200;
  background: #054b9f;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 15px 0px;
  padding: 10px 30px;
  transition: .5s;
  border-radius: 3px;
}
.deposit_modal .modal-content {
  background: linear-gradient(to bottom ,#e1f2fe,#fff);
  border: 2px solid #dfeffb;
  border-radius: 23px;
  padding: 10px;
}
.deposit_body >span{
 display: flex;
 justify-content: space-between;
}
.congrats_modal .modal-content {
  background-color: #fff;
  background-image: url("../src/assets/images/popup_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  background-size: 1180px;
}
.congrats_modal .modal-header{
 border-bottom: 0;
}
.congrats_modal .modal-header .btn-close{
  background-color: aliceblue;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 22px;
  height: 22px;
  background-size: 12px;
}
.congrats_modal .modal-header .btn-close:hover{
  opacity: 1;
}
.congrats_modal .congrats{
  max-width: 90%;
  margin: 60px auto;
}
.congrats_modal h3{
  font-family: 'Barney Rounded PERSONAL USE Regular';
  font-size: 63px;
  font-weight: 700;
  color: #0068b3;
  line-height: 30px;
}
.congrats_modal h5{
  font-size: 28px;
  font-weight: 700;
  text-align: right;
  margin-left: 3px;
  margin-top: -5px;
}
.quote_div {
  background: linear-gradient(359deg, #00c160, springgreen);  
  border-radius: 10px;
  padding: 15px;
  min-height: 200px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quote_div p {
  margin-bottom: 0;
  font-size: 18px;
  color: #002051;
  text-align: center;
}
a.icon_btn {
  color: #002051;
  font-size: 22px;
  line-height: 26px;
  display: block !important;
}
.plan_exp p.para {
  color: #fff;
  font-size: 20px;
}
.upgrade_timer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.upgrade_timer p {
  margin: 0 15px 0 0;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}
span.time_txt {
  color: #00c160;
  font-size: 24px;
  font-weight: 700;
  border: 3px solid #fff;
  border-radius: 50px;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upgrade_timer_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.time_dot {
  font-size: 24px;
  font-weight: 700;
  margin: 0 10px;
}


@media screen and (min-width: 1790px) {
  .global{
    max-width: 1320px;
    margin: 0 auto;
  }
  .global::before{
    left: -40px;
    width: 100%;
  }
  .faq{
    max-width: 1790px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1640px) {
  .page_header{
    background-color: #000a26;
    background-size: 1360px;
  }
}
@media screen and (max-width: 1399px) {
  .global {
    padding-bottom: 10px;
  }
  .global .img_box_out{
    transform: scale(0.7);
  }
  .dashboard .grid{
    grid-template-columns: 220px auto;
    gap: 20px;
  }
  .dashboard .box{
    padding: 25px 20px;
    margin-bottom: 20px;
  }
  .dashboard .mrgin .col-md-3 {
    padding-left: 5px;
  }
  .dashboard .selbox{
    margin: 0 10px;
  }
  .dashboard .busd_boxes{
    max-width: 100%;
  }
  .dashboard .board_chg .selbox p {
    font-size: 15px;
  }
.dashboard .mrgin{
  margin-bottom: 20px;
}

 .dashboard .box_bdr .box .fl_x button{
  font-size: 12px;
 }
 .dashboard .box h5{
  font-size: 20px;
 }
 .dashboard .board_chg .selbox {
  padding: 5px 9px;
 }
}

@media screen and (max-width: 1199px) {
  .h2tag {
    font-size: 46px;
  }

  .plan_box .box h3 {
    font-size: 40px;
  }

  .page_header h1 {
    font-size: 70px;
  }

  .faq .accordion-button {
    font-size: 18px;
  }

  .plan_exp .primary_btn {
    font-size: 27px;
  }
  .dashboard .grid {
    grid-template-columns: 220px 74.5%;
    gap: 20px;
  }
  .dashboard .mrgin .box_bdr{
    margin-bottom: 20px;
  }
  .dash_ban .flx{
    max-width: 50%;
  }
  .quote_div {
    min-height: 250px;
  }
}

@media screen and (max-width: 991px) {
  .main_navbar .navbar_right{
    flex-direction: column; 
    align-items: center;
  }
  .main_navbar .navbar_right button{
    margin: 0 0 10px !important;
  }
  a.icon_btn {
    display: flex !important;
    margin: auto !important;
  }
  .main_navbar .primary_btn{
    margin-bottom: 10px !important;
    margin-left: auto !important;
  }
  .main_navbar .navbar_right .dropdown {
    margin-right: 0 !important;
  }
  .main_navbar .navbar_right .dropdown .primary_btn{
    margin-bottom: 10px;
  }
  .page_header h1 {
    font-size: 50px;
  }
  .h2tag {
    font-size: 38px;
  }
  .main_navbar .offcanvas{
    transition: .5s;
    border: 0;
  }
  .above_info_box .above_box_flx div {
    margin: 0 17px;
  }

  .above_info_box .above_box_flx div p {
    width: 115px;
  }
  .global {
    padding-bottom: 30px;
    padding-top: 60px;
  }
  .global .flx{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .global .img_box_out {
    transform: scale(0.9);
   }
  .plan_box .box h3 {
    font-size: 35px;
  }

  .plan_exp .primary_btn {
    font-size: 24px;
  }
  .plan_box_dash .box{
    margin-bottom: 20px;
    text-align: left;
  }
  .plan_box_dash .col-lg-4:nth-last-child(1) .box{
    margin-bottom: 0;
  }
  .plan_box_dash .box.two, .plan_box_dash .box.three{
    justify-content: flex-start;
  }
  .dashboard .grid{
    display: block;
  }
  .dash_ban .flx{
    max-width: 60%;
  }
  .dash_ban h2 {
    font-size: 42px;
  }
  .quote_div {
    min-height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .h2tag {
    font-size: 30px;
 }
  .head{
    max-width: 100%;
  }
  .page_header h6 {
    font-size: 20px;
  }
  .page_header h1 {
    font-size: 40px;
  }
  .page_header{
    padding-top: 130px;
  }
  .global .container{
   text-align: center;
  }
  .global .flx{
    transform: rotate(45deg) scale(0.8) translateY(6px) translateX(22px) translateY(23px);
  }
  .global .box{
    height: 220px;
    width: 220px;
  }
  .global .img_box_out{
    width: 540px;
    height: 540px;
  }
  .global .img_box{
    width: 540px;
    height: 540px;
  }
  .global .para{
    margin-bottom: 20px;
  }
  .global .box:nth-last-child(1)::before {
    bottom: -31px;
    right: -17px;
    width: 314px;
  }
  .global .img_box::before {
    bottom: -33px;
  }
  .plan_box .col-md-4 {
    border-right: 0px ;
  }
  .plan_box .box{
    margin-bottom: 20px;
  }
  .plan_box .col-md-4:nth-last-child(1) .box{
    margin-bottom: 0;
  }
  .plan_exp .primary_btn {
    font-size: 21px;
  }
  .plan_exp h5{
    line-height: unset;
  }
  .p_activity .activity_box {
    padding: 17px;
  }

  .p_activity p {
    font-size: 17px;
  }

  .p_activity .flxbox {
    flex-wrap: wrap;
  }

  .p_activity .flxbox div {
    margin: 5px;
    margin-right: 15px;
    margin-left: 0;
  }

  .p_activity .activity_box_flx {
    flex-direction: column;
    align-items: baseline;
  }

  .p_activity .rightbtn p {
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-top: 17px;
  }
  .faq::before{
    display: none;
  }
  .faq h2{
    text-align: center;
  }
  .dash_ban h2 {
    font-size: 38px;
  }
  .dash_ban .flx {
    max-width: 90%;
 }
 .dashboard .busd_boxes{
   display: block;
 }
 .dashboard .selbox{
  margin-bottom: 10px;
 }
 .dashboard .box_bdr .input-group{
  max-width: 90%;
 }
 .dashboard .busd_boxes .primary_btn {
  margin: 10px auto;
  /* width: 93%; */
}
 .congrats_modal .congrats{
  text-align: center;
  max-width: 100%;
 }
 .congrats_modal h3 {
   font-size: 48px;
   margin-bottom: 15px;
 }
 .congrats_modal h5{
  margin-top: 0;
  text-align: center;
 }
 .congrats_modal .modal-header .btn-close {
  right: 5px;
 }
 .deposit_body >span{
  flex-direction: column;
  align-items: flex-start;
 }
 .quote_div {
  min-height: 120px;
  }
  .dashboard .lvl_flx .primary_btn{
    width: 100%;
  }
  .dashboard .lvl_flx{
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard .lvl_flx div {
    width: 100%;
  }
  .dashboard .lvl_flx div+div{
    margin-top: 15px;
    margin-left: 0;
  }
  .dashboard .busd_boxes >div+div {
    margin-left: 0 !important;
    margin-top: 15px;
  }
}

@media screen and (max-width: 575px) { 
  .page_header h1 {
    font-size: 32px;
  }
  .p_activity .activity_box .seemorebutn{
    width: unset;
    padding: 15px 50px;
  }
  .global .img_box_out {
    transform: scale(0.9);
    width: 320px;
    height: 320px;

    width: 340px;
    height: 340px;
  }
  .global .img_box {
    width: 320px;
    height: 320px;

    width: 340px;
    height: 340px;
    border: 15px solid #fff;
  }
  .global .box {
    height: 144px;
    width: 144px;
  }
  .global .box .imgb {
    width: 45px;
    height: 45px;
  }
  .global .box  .imgb img {
    height: 22px;
  }
  .global .box p{
    font-size: 12px;
  }
  .global .img_box::before {
    bottom: -20px;
  }
  .global .box:nth-last-child(1)::before {
    bottom: -60px;
    right: -15px;
    width: 294px;
  }
  .global .flx {
    transform: rotate(45deg) scale(0.8) translateY(4px) translateX(1px) translateY(-2px);
  }
  .congrats_modal h3 {
    font-size: 40px;
  }
  .congrats_modal h5 {
    font-size: 24px;
  }
}

@media screen and (max-width: 350px) {
  .global .col-lg-5{
    overflow: hidden;
  }
}